<template>
    <v-container class="fill-height">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-dialog :value="true" max-width="575" persistent>
                    <UserLoginCard />
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserLoginCard from '../components/login/UserLoginCard.vue';

export default {
    name: 'UserLogin',

    components: {
        UserLoginCard
    }
};
</script>
